import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter as Router } from "connected-react-router"

import configureStore, { historyObject } from "./store"

// import App from "./App"
// import CookieConsent from "components/CookieConsent"

import "./index.sass"
import "./App.sass"
import "components/AppPromo.sass"
import "components/Button.sass"
import "components/CarButton.sass"
import "components/CookieConsent.sass"
import "components/Footer.sass"
import "components/FormInput.sass"
import "components/Header.sass"
import "components/Logo.sass"
import "components/SearchField.sass"
import "layouts/SimplePage.sass"
import "layouts/SettingsPage.sass"
import "pages/About.sass"
import "pages/Contract.sass"
import "pages/Detail.sass"
import "pages/DetailSidebar.sass"
import "pages/Form.sass"
import "pages/Landing.sass"
import "pages/Legal.sass"
import "pages/LoginForm.sass"
import "pages/MyGarage.sass"
import "pages/NotFound.sass"
import "pages/Submit.sass"
import "pages/UsedVehicles.sass"

window.AvtologStore = configureStore()

hydrate()

if (
  process.env.NODE_ENV === "development" ||
  window.location.href.includes("netlify")
) {
  // const { makeServer } = require("./api/mirage")
  // makeServer(process.env.NODE_ENV)
}

function Wrapper({ children }) {
  return (
    <React.StrictMode>
      <Provider store={window.AvtologStore}>
        <Router history={historyObject}>{children}</Router>
      </Provider>
    </React.StrictMode>
  )
}

function hydrate() {
  // Always hydrate CookieConsent
  const consentRoot = document.getElementById("consent-root")
  if (consentRoot) {
    import("./components/CookieConsent.js").then(
      ({ default: CookieConsent }) => {
        render(
          <Wrapper>
            <CookieConsent />
          </Wrapper>,
          consentRoot,
        )
      },
    )
  }

  // Exit early by hydrating App
  const appRoot = document.getElementById("app-root")
  if (appRoot) {
    import("./App.js").then(({ default: App }) => {
      render(
        <Wrapper>
          <App />
        </Wrapper>,
        appRoot,
      )
    })

    return true
  }

  // Otherwise hydrate individual components:

  // - Header
  const headerRoot = document.getElementById("header-root")
  if (headerRoot) {
    import("./components/Header").then(({ default: Header }) => {
      const searchValue = document.querySelector("form.SearchField input").value

      const headerProps = {
        searchValue,
      }

      if (headerRoot.classList.contains("isTall")) {
        headerProps.isTall = true
        headerProps.isCentered = true
      } else {
        headerProps.isSlim = true
        headerProps.isDetail = true
      }

      render(
        <Wrapper>
          <Header {...headerProps} />
        </Wrapper>,
        headerRoot,
      )
    })
  }

  // - Sidebar
  const sidebarRoot = document.getElementById("sidebar-root")
  if (sidebarRoot) {
    import("./pages/DetailSidebar").then(({ default: DetailSidebar }) => {
      render(
        <Wrapper>
          <DetailSidebar />
        </Wrapper>,
        sidebarRoot,
      )
    })
  }
}

// import * as serviceWorker from "./serviceWorker"
// serviceWorker.unregister()
