import { createBrowserHistory } from "history"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { routerMiddleware } from "connected-react-router"

import thunk from "redux-thunk"

import createRootReducer from "./reducers"

export const historyObject = createBrowserHistory()

export default function configureStore(initialState = {}) {
  return createStore(
    createRootReducer(historyObject),
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk, routerMiddleware(historyObject)),
    ),
  )
}
